import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className='main-footer-componenet'>
      <div className="content-footer">
        <div className="left-footer">
          <p>©DevStacks-2024</p>
        </div>
        <div className="right-container">
          <p><a href="behnace">Behance</a></p>
          <p><a href="twitter">twitter</a></p>
          <p><a href="instagram">Instagram</a></p>
        </div>
      </div>
    </div>
  )
}

export default Footer