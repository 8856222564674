import React from 'react';
import './TextRotator.css';

const TextRotator = () => {
    return (
        <div className='text-main-component'>
            <div className="text-animations">
                <p className="text-item">
                    <span>
                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 4.5L34.6234 22.7094L46 8.25129L39.1672 25.3328L56.2487 18.5L41.7906 29.8766L60 32.5L41.7906 35.1234L56.2487 46.5L39.1672 39.6672L46 56.7487L34.6234 42.2906L32 60.5L29.3766 42.2906L18 56.7487L24.8328 39.6672L7.75129 46.5L22.2094 35.1234L4 32.5L22.2094 29.8766L7.75129 18.5L24.8328 25.3328L18 8.25129L29.3766 22.7094L32 4.5Z" fill="#CBCBCB"/>
                        </svg>
                    </span>
                    <span>
                        DEVELOPER
                    </span>
                    <span>
                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 4.5L34.6234 22.7094L46 8.25129L39.1672 25.3328L56.2487 18.5L41.7906 29.8766L60 32.5L41.7906 35.1234L56.2487 46.5L39.1672 39.6672L46 56.7487L34.6234 42.2906L32 60.5L29.3766 42.2906L18 56.7487L24.8328 39.6672L7.75129 46.5L22.2094 35.1234L4 32.5L22.2094 29.8766L7.75129 18.5L24.8328 25.3328L18 8.25129L29.3766 22.7094L32 4.5Z" fill="#CBCBCB"/>
                        </svg>
                    </span>
                    <span>
                        WEBSITE APPLICATIONS
                    </span>
                    <span>
                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 4.5L34.6234 22.7094L46 8.25129L39.1672 25.3328L56.2487 18.5L41.7906 29.8766L60 32.5L41.7906 35.1234L56.2487 46.5L39.1672 39.6672L46 56.7487L34.6234 42.2906L32 60.5L29.3766 42.2906L18 56.7487L24.8328 39.6672L7.75129 46.5L22.2094 35.1234L4 32.5L22.2094 29.8766L7.75129 18.5L24.8328 25.3328L18 8.25129L29.3766 22.7094L32 4.5Z" fill="#CBCBCB"/>
                        </svg>
                    </span>
                    <span>
                        FULL STACK DEVELOPER
                    </span>
                    <span>
                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 4.5L34.6234 22.7094L46 8.25129L39.1672 25.3328L56.2487 18.5L41.7906 29.8766L60 32.5L41.7906 35.1234L56.2487 46.5L39.1672 39.6672L46 56.7487L34.6234 42.2906L32 60.5L29.3766 42.2906L18 56.7487L24.8328 39.6672L7.75129 46.5L22.2094 35.1234L4 32.5L22.2094 29.8766L7.75129 18.5L24.8328 25.3328L18 8.25129L29.3766 22.7094L32 4.5Z" fill="#CBCBCB"/>
                        </svg>
                    </span>
                    <span>
                        UI & UX
                    </span>
                    <span>
                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 4.5L34.6234 22.7094L46 8.25129L39.1672 25.3328L56.2487 18.5L41.7906 29.8766L60 32.5L41.7906 35.1234L56.2487 46.5L39.1672 39.6672L46 56.7487L34.6234 42.2906L32 60.5L29.3766 42.2906L18 56.7487L24.8328 39.6672L7.75129 46.5L22.2094 35.1234L4 32.5L22.2094 29.8766L7.75129 18.5L24.8328 25.3328L18 8.25129L29.3766 22.7094L32 4.5Z" fill="#CBCBCB"/>
                        </svg>
                    </span>
                    <span>
                        JAVASCRIPT
                    </span>
                </p>
            </div>
        </div>
    );
}

export default TextRotator;
