import React from 'react';
import './Expertise.css';

const Expertise = () => {
  return (
    <div className='main-expertise-component'>
      <div className="expertise-feild">
        <h2><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 0L17.5818 10.0968L24 2.14359L20.3215 11.6785L29.8564 8L21.9032 14.4182L32 16L21.9032 17.5818L29.8564 24L20.3215 20.3215L24 29.8564L17.5818 21.9032L16 32L14.4182 21.9032L8 29.8564L11.6785 20.3215L2.14359 24L10.0968 17.5818L0 16L10.0968 14.4182L2.14359 8L11.6785 11.6785L8 2.14359L14.4182 10.0968L16 0Z" fill="#CBCBCB" />
        </svg>Expertise</h2>

        <div className="multiple-field">
          <div className="field">
            <h3>▪ Branding</h3>
            <p>Transforming brands into unforgettable experiences. With Devstacks, your brand identity will resonate with your audience, leaving a lasting impression.</p>
          </div>
          <div className="field">
            <h3>▪ UI & UX</h3>
            <p>Crafting intuitive interfaces and delightful user experiences. At Devstacks, we prioritize usability and aesthetics to ensure user satisfaction.</p>
          </div>
          <div className="field">
            <h3>▪ Website Development</h3>
            <p>Building responsive and dynamic websites tailored to your needs. Our team at Devstacks ensures your website stands out in the digital landscape.</p>
          </div>
          <div className="field">
            <h3>▪ Web-Applications</h3>
            <p>Developing robust and scalable web applications for your business. With Devstacks, your web applications will be powerful tools driving your success.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
