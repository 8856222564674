import React, { useState } from 'react';
import './Questions.css';

const Questions = () => {
    const [expandedQuestion, setExpandedQuestion] = useState(null);

    const queAns = [{
        id: 1,
        question: "What is Website development Process?",
        answer: "Our development process revolves around four core phases: research, design, prototype, and test. In the research phase, we delve into understanding user needs deeply. Then, in the design phase, we create wireframes and visual designs that resonate with these needs. Moving forward, the prototype phase involves developing interactive models for thorough testing. Finally, in the test phase, we gather user feedback to refine and enhance the design further."
    }, {
        id: 2,
        question: "Do you use Javascript?",
        answer: "Yes, we use JavaScript extensively in our development process. JavaScript is a fundamental language for building interactive and dynamic web applications. It enables us to create responsive user interfaces, handle client-side logic, and interact with server-side components."
    }, {
        id: 3,
        question: "Can you explain your approach to responsive web design?",
        answer: "We follow a mobile-first approach to responsive web design, ensuring that our websites and applications are optimized for various screen sizes and devices. We use media queries, flexible grids, and responsive design frameworks like Bootstrap or Foundation to create fluid and adaptive layouts."
    }];


    const handleQuestionClick = (id) => {
        if (expandedQuestion === id) {
            setExpandedQuestion(null);
        } else {
            setExpandedQuestion(id);
        }
    };

    return (
        <div className='main-question-container'>
            <div className="content-questions-container">
                <h3 data-aos="fade-down"><svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0.5L17.8885 10.6877L25.4046 3.55573L20.9443 12.9078L31.2169 11.5557L22.1115 16.5L31.2169 21.4443L20.9443 20.0922L25.4046 29.4443L17.8885 22.3123L16 32.5L14.1115 22.3123L6.59544 29.4443L11.0557 20.0922L0.783095 21.4443L9.88854 16.5L0.783095 11.5557L11.0557 12.9078L6.59544 3.55573L14.1115 10.6877L16 0.5Z" fill="#CBCBCB" />
                </svg>

                    Frequently Asked Questions</h3>
                <div data-aos="fade-left" className="questions-answers">
                    {queAns.map(item => (
                        <div key={item.id}>
                            <h4 onClick={() => handleQuestionClick(item.id)}>{item.question}</h4>
                            {expandedQuestion === item.id && <p>{item.answer}</p>}
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default Questions;
