
import React from 'react';
import { Link } from 'react-router-dom';
import images from '../Assets/Assests';
import './Blogs.css'
const Blogs = () => {
  const blogs = [
    {
      id: 1,
      image: images.Blog1,
      date: 'Oct 7, 2023',
      title: 'The Importance of Responsive Web Design',
      description: 'Explore why responsive web design is crucial for modern websites.',
    },
    {
      id: 2,
      image: images.Blog2,
      date: 'Dec 23, 2023',
      title: 'Optimizing Your Website for Search Engines',
      description: 'Learn strategies to optimize your website for better search engine rankings.',
    },
  ];

  return (
    <div className="blogs-main-container">
      <div className="blogs-content-container">
        <div className="upper-section">
          <h2>Blogs</h2>
          <Link to="/all-blogs">
            <button>
              View all{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h13M12 5l7 7-7 7" />
              </svg>
            </button>
          </Link>
        </div>
        {blogs.map((blog) => (
          <div key={blog.id} className="content-container1">
            <img src={blog.image} alt="" />
            <div className="text-container">
              <p>{blog.date}</p>
              <h4>{blog.title}</h4>
              <Link to={`/blog/${blog.id}`}>
                <div className="buttons">
                  <button>Read</button>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
