
import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import images from '../../Assets/Assests';
import BlogPost from './BlogsPost';

const BlogsContainer = () => {
  const blogs = [
    {
      id: 1,
      img: images.Blog1,
      title: "The Importance of Responsive Web Design",
      content: "Responsive web design ensures that your website adapts to different screen sizes and devices. This is crucial in today's mobile-first era, as more users access the internet from smartphones and tablets. A responsive website provides a seamless user experience, leading to higher engagement and conversions.",
    },
    {
      id: 2,
      img: images.Blog2,
      title: "Optimizing Your Website for Search Engines",
      content: "Search engine optimization (SEO) is essential for increasing your website's visibility on search engine results pages (SERPs). By optimizing your website's content, meta tags, and structure, you can improve its ranking on search engines like Google. This, in turn, drives organic traffic to your site and boosts your online presence.",
    },
    {
      id: 3,
      img: images.Blog3,
      title: "The Impact of Social Media Marketing",
      content: "Social media marketing allows businesses to connect with their target audience and build brand awareness. Platforms like Facebook, Instagram, and Twitter offer powerful tools for reaching potential customers and engaging with existing ones. By creating compelling content and leveraging social media advertising, businesses can drive traffic to their websites and increase sales.",
    },
    {
      id: 4,
      img: images.Blog4,
      title: "Best Practices for User Experience (UX) Design",
      content: "User experience (UX) design focuses on creating seamless interactions between users and websites or applications. By following best practices such as intuitive navigation, clear calls-to-action, and fast loading times, UX designers can enhance the usability and satisfaction of a digital product. A positive user experience leads to higher customer retention and loyalty.",
    },
    {
      id: 5,
      img: images.Blog5,
      title: "Harnessing the Power of Content Marketing",
      content: "Content marketing involves creating and distributing valuable, relevant content to attract and retain a target audience. Blog posts, articles, videos, and infographics are examples of content that can be used to educate, entertain, and engage users. By consistently delivering high-quality content, businesses can establish themselves as industry authorities and drive conversions.",
    },
    {
      id: 6,
      img: images.Blog6,
      title: "The Role of Video in Digital Marketing",
      content: "Video has become an increasingly popular medium for digital marketing due to its ability to capture attention and convey information effectively. Platforms like YouTube, TikTok, and Instagram Reels offer opportunities for businesses to create engaging video content and reach a wide audience. Video marketing can humanize brands, showcase products, and tell compelling stories.",
    },
    {
      id: 7,
      img: images.Blog7,
      title: "Understanding the Basics of Web Development",
      content: "Web development encompasses the creation and maintenance of websites and web applications. Frontend development focuses on the user interface and user experience, while backend development deals with server-side logic and database management. By mastering programming languages like HTML, CSS, and JavaScript, developers can build functional and visually appealing websites.",
    },
  ];

  return (
    <div className="blogs-main-container">
      <div className="blogs-content-container">
        {blogs.map(blog => (
          <div key={blog.id} className="content-container1">
            <img src={blog.img} alt="" />
            <div className="text-container">
              <p>{blog.date}</p>
              <h4>{blog.title}</h4>
              <Link to={`/blog/${blog.id}`} className="read-button">
                Read
              </Link>
            </div>
          </div>
        ))}
      </div>
      <Routes>
        {blogs.map(blog => (
          <Route key={blog.id} path={`/blog/${blog.id}`} element={<BlogPost blog={blog} />} />
        ))}
      </Routes>
    </div>
  );
};

export default BlogsContainer;
