// Home.js
import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../../Header/Header';
import Expertise from '../../Expertise/Expertise';
import Experience from '../../Experience/Experience';
import Blogs from '../../Blogs/Blogs';
import Reviews from '../../Reviews/Reviews';
import Questions from '../../Questions/Questions';
import TextRotator from '../../TextRotator/TextRotator';
import Footer from '../../Footer/Footer';
import PopUp from '../../PopUp/PopUp';
import ExperienceAboutus from '../../../AboutComponents/ExperienceAboutus/ExperienceAboutus';

const Home = () => {
  // useEffect(() => {
  //   const popup = document.querySelector('.popup-main-container');

  //   setTimeout(() => {
  //     popup.style.display = 'none';
  //   }, 4000);
  // }, [])
  return (
    <div>
      <Helmet>
        <title>Devstacks</title>
        <meta name='description' content='Discover the magic of creating your dream website with ease and delight. Start your journey to an enchanting web experience today.' />
        <link rel="canonical" href="https://devstacks.in/" />
        <meta name='keywords' content='website, web development, creativity, full stack developer,website creators' />
        <meta property='og:title' content='Devstacks' />
        <meta property='og:description' content='Discover the magic of creating your dream website with ease and delight. Start your journey to an enchanting web experience today.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://devstacks.in/' />
      </Helmet>
      {/* <PopUp /> */}
      <Header />
      <Expertise />
      <ExperienceAboutus />
      <Blogs />
      <Reviews />
      <Questions />
      <TextRotator />
      <div className="sub-footer">
        <h1>Begin a conversation</h1>
        <p>
          <a href="mailto:devstackswork@gmail.com" target="_blank" className='gmail-link'>
            devstackswork@gmail.com
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.16699 4.6665H11.8337M11.8337 4.6665V15.3332M11.8337 4.6665L1.16699 15.3332" stroke="white" strokeWidth="2" />
            </svg>
          </a>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
