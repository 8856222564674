import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ProjectContext } from "../ProjectContext/ProjectContext";
import "./ProductDetails.css";
import Footer from "../../Components/Footer/Footer";
import Loader from "../../Components/Loader/Loader";

const ProductDetails = () => {
  const { id } = useParams();
  const projects = useContext(ProjectContext);
  const project = projects.find((project) => project.id === parseInt(id));

  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const convertToEmbedLink = (regularLink) => {
    const match = regularLink.match(regex);
    if (match) {
      const videoCode = match[1];
      return `https://www.youtube.com/embed/${videoCode}`;
    }
    return null;
  };

  if (!project) {
    return <p>Project not found.</p>;
  }

  return (
    <div>
      <Loader pageKey={`page${project.id}`} />
      <div className="project-details-container">
        <h1>{project.name}</h1>
        <div className="image-content-text-product">
          <div className="gallery">
            {project.four_images.map((image, index) => (
              <img
                key={index}
                className="card"
                src={image}
                alt={`Project preview ${index + 1}`}
                style={{ height: "250px" }}
              />
            ))}
          </div>
          <div className="title-information">
            <h2>{project.title}</h2>
            <p>{project.description}</p>
          </div>
        </div>
        <div className="complete-tour">
          <h1>Have a Complete Look</h1>
          <div className="feature-video-section">
            <iframe
              className="work-detail-video"
              width="1120"
              height="630"
              src={convertToEmbedLink(project.youtube_link)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
