import React from 'react'
import './ExperienceAboutus.css'
const ExperienceAboutus = () => {
  return (
    <div className="custom-experience-container">
      <div className="custom-content-container">
        <h3 data-aos="fade-down">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 0L17.8885 10.1877L25.4046 3.05573L20.9443 12.4078L31.2169 11.0557L22.1115 16L31.2169 20.9443L20.9443 19.5922L25.4046 28.9443L17.8885 21.8123L16 32L14.1115 21.8123L6.59544 28.9443L11.0557 19.5922L0.783095 20.9443L9.88854 16L0.783095 11.0557L11.0557 12.4078L6.59544 3.05573L14.1115 10.1877L16 0Z"
              fill="#CBCBCB"
            />
          </svg>{' '}
          Experience
        </h3>
        <div className="custom-member-container">
          <div className="custom-container">
            <h2 data-aos="fade-right">Leading Developer</h2>
            <div className="custom-name-experience" data-aos="fade-left">
              <h5>Jatin, Lalit</h5>
              <p>2020-2024</p>
            </div>
          </div>
          <div className="custom-container">
            <h2 data-aos="fade-right">Backend Developer</h2>
            <div className="custom-name-experience" data-aos="fade-left">
              <h5>Lalit Soni</h5>
              <p>2020-2024</p>
            </div>
          </div>
          <div className="custom-container">
            <h2 data-aos="fade-right">UI Developer</h2>
            <div className="custom-name-experience" data-aos="fade-left">
              <h5>Lalit Soni</h5>
              <p>2019-2024</p>
            </div>
          </div>
          <div className="custom-container">
            <h2 data-aos="fade-right">Frontend Developer</h2>
            <div className="custom-name-experience" data-aos="fade-left">
              <h5>Jatin Mistry</h5>
              <p>2020-2024</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExperienceAboutus