import React, { useState } from "react";
import "./Reviews.css";
import images from '../Assets/Assests';

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const data = [
    {
      id: 1,
      name: "Rajesh Nakar",
      cname: "RN Vaastu",
      img: images.maharaj,
      review:
        "DevStacks crafts websites that are both user-friendly and visually appealing. Their attention to detail and commitment to quality shine through in every project. It's evident that they prioritize creating practical and effective digital solutions. With DevStacks, you can trust that your online presence will be in capable hands."
    },
    {
      id: 2,
      name: "Danish Jain",
      cname: "JetFly",
      img: images.review2,
      review:
        "We extend a big thank you to Devstacks for their outstanding work in improving our flight booking process. Your efforts have made our operations smoother and our customers happier. We appreciate you!."
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
  };

  return (
    <div className="main-reviews-container">
      <div className="content-container-review">
        <h1 data-aos="fade-down">
          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 0.5L17.8885 10.6877L25.4046 3.55573L20.9443 12.9078L31.2169 11.5557L22.1115 16.5L31.2169 21.4443L20.9443 20.0922L25.4046 29.4443L17.8885 22.3123L16 32.5L14.1115 22.3123L6.59544 29.4443L11.0557 20.0922L0.783095 21.4443L9.88854 16.5L0.783095 11.5557L11.0557 12.9078L6.59544 3.55573L14.1115 10.6877L16 0.5Z" fill="#CBCBCB" />
          </svg>{" "}
          What they say:-
        </h1>

        <div className="content1-review">
          <div className="left-review" data-aos="fade-right">
            <img src={data[currentIndex].img} alt="" />
            <div className="names">
              <h4>{data[currentIndex].name}</h4>
              <p >{data[currentIndex].cname}</p>
            </div>
          </div>

          <div data-aos="fade-left" className="info-review">
            <p>{data[currentIndex].review}</p>
          </div>
        </div>
        <div className="arrows-container">
          <svg data-aos="fade-right"
            width="88"
            height="89"
            viewBox="0 0 88 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handlePrevious}
          >
            <g filter="url(#filter0_d_1_291)">
              <rect
                x="20"
                y="8.5"
                width="40"
                height="40"
                rx="20"
                fill="#CBCBCB"
              />
              <path
                d="M46.5 28.5H34M34 28.5L38 32.5M34 28.5L38 24.5"
                stroke="#010208"
                stroke-width="1.5"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_1_291"
                x="0"
                y="0.5"
                width="88"
                height="88"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="16" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.964706 0 0 0 0 0.32549 0 0 0 0 0.25098 0 0 0 0.16 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1_291"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1_291"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          <svg data-aos="fade-left"
            width="104"
            height="105"
            viewBox="0 0 104 105"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleNext}
          >
            <g filter="url(#filter0_d_1_293)">
              <rect x="28" y="16.5" width="40" height="40" rx="20" fill="white" />
              <path
                d="M42.0005 36.5H54.5005M54.5005 36.5L50.5005 32.5M54.5005 36.5L50.5005 40.5"
                stroke="#010208"
                stroke-width="1.5"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_1_293"
                x="0"
                y="0.5"
                width="104"
                height="104"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="16" />
                <feGaussianBlur stdDeviation="16" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 0.16 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1_293"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1_293"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </div>

  );
};

export default Reviews;
