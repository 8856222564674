import React from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = 'service_mc98dax';

    const templateID = 'template_44u747s';

    const userID = 'eapc2xpYfXhWo4WYC';

    const formData = {
      from_name: e.target.Name.value,
      reply_to: e.target.Email.value,
      phone_number: e.target['Phone Number'].value,
      budget: e.target.Budget.value,
      goals: e.target.Goals.value
    };

    // Send email using EmailJS
    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        // Handle success (e.g., show success message)
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        // Handle error (e.g., show error message)
      });
  }


  return (
    <div>
      <div className='contact-form-component'>
        <div className="contact-form-content">
          <form onSubmit={handleSubmit}>
            <input data-aos="fade-right" type="text" name="Name" id="name" placeholder='Name' required />
            <input data-aos="fade-right" type="email" name='Email' id='name' placeholder='Email' required />
            <input data-aos="fade-right" type="tel" name='Phone Number' id='phonenumber' placeholder='Phone Number' />
            <input data-aos="fade-right" type="number" name="Budget" id="budget" placeholder='Budget' />
            <input data-aos="fade-right" type="text" name="Goals" id="goals" placeholder='Goals' />
            <div className="form-button">
              <button type="submit">Send Enquiry<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M134.436 73.34C134.436 74.8656 133.765 76.2694 132.544 77.4294L92.0166 117.956C90.7348 119.116 89.4531 119.604 88.0493 119.604C84.9975 119.604 82.6781 117.408 82.6781 114.356C82.6781 112.952 83.2275 111.487 84.2044 110.51L97.8149 96.5944L118.445 77.8563L103.613 78.8331H26.465C23.2912 78.8331 21.0937 76.5138 21.0937 73.34C21.0937 70.1663 23.2912 67.9075 26.465 67.9075H103.613L118.384 68.8231L97.8149 50.0854L84.2044 36.2305C83.1662 35.1929 82.6781 33.7891 82.6781 32.3243C82.6781 29.3335 84.9975 27.0752 88.0493 27.0752C89.4531 27.0752 90.7958 27.6245 92.1387 28.9063L132.544 69.2506C133.765 70.4713 134.436 71.875 134.436 73.34Z" fill="white" />
              </svg></button>
            </div>
          </form>
        </div>
      </div>

      <div className="sub-footer">
        <h1>Begin a conversation</h1>
        <p>
          <a href="mailto:devstackswork@gmail.com" target="_blank" className='gmail-link'>
            devstackswork@gmail.com
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.16699 4.6665H11.8337M11.8337 4.6665V15.3332M11.8337 4.6665L1.16699 15.3332" stroke="white" stroke-width="2" />
            </svg>
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;