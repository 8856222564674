import React from 'react';
import { Helmet } from 'react-helmet-async';
import ContactHeader from '../../../ContactComponents/ContactHeader/ContactHeader';
import ContactForm from '../../../ContactComponents/ContactForm/ContactForm';
import Footer from '../../Footer/Footer';

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Devstacks</title>
        <meta name='description' content='Get in touch with us through our contact form. Fill out the form to send us your queries, feedback, or any other inquiries. We are here to assist you!' />
        <link rel="canonical" href="/Contact" />
        <meta name='keywords' content='contact, get in touch, feedback, inquiries,website' />
        <meta property='og:title' content='Contact Us - Devstacks' />
        <meta property='og:description' content='Get in touch with us through our contact form. Fill out the form to send us your queries, feedback, or any other inquiries. We are here to assist you!' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://devstacks.in/Contact' />
      </Helmet>
      <ContactHeader />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Contact;
