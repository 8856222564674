import React from 'react';
import { useParams } from 'react-router-dom';

const BlogPost = ({ blogs }) => {
  const { id } = useParams();
  const blog = blogs && blogs.find(blog => blog.id === parseInt(id));
  return (
    <div>
      {blog ? (
        <>
          <img src={blog.img} alt="" />
          <p>{blog.date}</p>
          <h2>{blog.title}</h2>
          <p>{blog.content}</p>
        </>
      ) : (
        <div>Blog post not found</div>
      )}
    </div>
  );
};

export default BlogPost;
