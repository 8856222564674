import React from 'react';
import './Navbar.css';
import { Link, NavLink } from 'react-router-dom';
import images from '../Assets/Assests'
const Navbar = () => {
  return (
    <div className='main-navbar-container'>
      <div className="left-container">
      <a href="https://www.devstacks.in" target="_blank" rel="noopener noreferrer">
  <img src={images.logo} alt="logo" />
</a>      </div>
      <div className="middle-container">
        <ul>
          <li><NavLink exact to="/" activeClassName="active"><span>Home</span></NavLink></li>
          <li><NavLink to="/about" activeClassName="active"><span>About</span></NavLink></li>
          <li><NavLink to="/projects" activeClassName="active"><span>Projects</span></NavLink></li>
          <li><NavLink to="/contact" activeClassName="active"><span>Contact</span></NavLink></li>
        </ul>
      </div>
      <div className="right-container">
        <Link to="/contact">
          <button>Hire me</button>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
