import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./Chatbot.css";
import chatbotsvg from '../Components/Assets/Assests';
import close from '../Components/Assets/Assests';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [showGreeting, setShowGreeting] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const emailServiceId = "service_qxzawdd";
  const emailTemplateId = "template_dpl175f";
  const emailUserId = "eapc2xpYfXhWo4WYC";

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setShowCloseIcon(!showCloseIcon); // Toggle close icon visibility
    if (!isOpen && currentQuestion === -1) {
      setTimeout(() => {
        setCurrentQuestion(0);
        setShowGreeting(false);
      }, 2000);
    }
  };

  const handleAnswer = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    const current = questions[currentQuestion];
    if (current && current.message) {
      setShowMessage(true);
    } else {
      handleNextQuestion(option);
    }
  };

  const handleNextQuestion = (option) => {
    const current = questions[currentQuestion];
    if (currentQuestion < questions.length - 1) {
      const nextQuestion = questions.find(
        (q) =>
          q?.id === current?.id + 1 ||
          (q?.parentQuestionId === current?.id && q?.parentOption === option)
      );
      if (nextQuestion) {
        setCurrentQuestion(nextQuestion.id);
      }
    } else {
      setIsOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleInputSubmit = () => {
    const updatedResponses = [...selectedOptions];

    if (currentQuestion === 3) {
      updatedResponses.push(userInput); // Add name to responses
    } else if (currentQuestion === 4) {
      updatedResponses.push(userInput); // Add phone number to responses
    } else if (currentQuestion === 5) {
      updatedResponses.push(userInput); // Add email to responses
    }

    setSelectedOptions(updatedResponses);
    setUserInput('');

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // Send collected user responses via email
      sendEmail(updatedResponses);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
        handleNextQuestion();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  const questions = [
    {
      id: 0,
      text: "What do you need a website for?",
      options: ["Business", "Ecommerce", "Personal", "Applications", "Other"],
      message: "Excellent!",
    },
    {
      id: 1,
      text: "Do you need full stack development?",
      options: ["Yes", "No"],
      message: "Great!",
    },
    {
      id: 2,
      text: "What do you need?",
      options: ["Frontend", "Backend", "Full-stack", "UI-UX", "Other"],
      parentQuestionId: 1,
      parentOption: "Yes",
    },
    {
      id: 3,
      text: "What's your budget?",
      options: ["$35k-$50k", "$50k-$80k", "$80k-Above", "Other"],
      message: "Excellent budget!",
    },
    {
      id: 4,
      text: "Enter your name:",
      input: true,
    },
    {
      id: 5,
      text: "Enter your phone number:",
      input: true,
    },
    {
      id: 6,
      text: "Enter your email:",
      input: true,
    },
  ];

  const sendEmail = (userResponses) => {
    const formattedResponses = userResponses.map((response, index) => {
      if (index === 3) {
        return `${index + 1}. Budget: ${response}`;
      } else if (index === 4) {
        return `${index + 1}. Name: ${response}`;
      } else if (index === 5) {
        return `${index + 1}. Phone Number: ${response}`;
      } else if (index === 6) {
        return `${index + 1}. Email: ${response}`;
      } else {
        return `${index + 1}. ${response}`;
      }
    }).join('\n');

    const templateParams = {
      from_name: 'Chatbot',
      to_name: 'Devstacks',
      message: formattedResponses,
    };

    emailjs
      .send(emailServiceId, emailTemplateId, templateParams, emailUserId)
      .then((response) => {
        console.log('Email sent successfully!', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div className="chatbot-container">
      <div className="chatIcon" onClick={toggleChat}>
        {showCloseIcon ? (
          <img src={close.close} alt="Close" className="active" />
        ) : (
          <img src={chatbotsvg.chatbotsvg} alt="Chat" />
        )}
      </div>
      {isOpen && (
        <div className="chatBox">
          {showGreeting && (
            <div className="greeting">
              <p>Hello, we are Devstacks. Welcome!</p>
            </div>
          )}
          {currentQuestion !== -1 && (
            <div className="question">
              <p>{questions[currentQuestion]?.text}</p>
              {questions[currentQuestion]?.input ? (
                <div>
                  <input
                    type="text"
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleInputSubmit();
                      }
                    }}
                  />
                  <button onClick={handleInputSubmit}>Submit</button>
                </div>
              ) : (
                <div className="ul-li-chatbot">
                  <ul>
                    {questions[currentQuestion]?.options?.map((option, index) => (
                      <li key={index} onClick={() => handleAnswer(option)}>
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {showMessage && questions[currentQuestion]?.message && (
                <div className="message">
                  <p>{questions[currentQuestion].message}</p>
                </div>
              )}
            </div>
          )}
          {
            currentQuestion === questions.length - 1 &&
            selectedOptions.length === questions.length && (
              <div className="thankYou">
                <p>Thanks for your replies. We will reply to you soon.</p>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

export default Chatbot;