import React, { useEffect, useState } from 'react';
import './Loader.css';

const Loader = ({ pageKey }) => {
    const [count, setCount] = useState(4);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const loaderShown = localStorage.getItem(`loaderShown_${pageKey}`);

        if (!loaderShown) {
            setVisible(true);
            const interval = setInterval(() => {
                setCount(prevCount => {
                    if (prevCount <= 1) {
                        clearInterval(interval);
                        localStorage.setItem(`loaderShown_${pageKey}`, 'true');
                        setVisible(false);
                        return 0; // Return 0 when the countdown finishes
                    }
                    return prevCount - 1;
                });
            }, 1000);

            return () => clearInterval(interval); // Cleanup on component unmount
        }
    }, [pageKey]);

    return (
        visible && (
            <div className='loader'>
                <p className='loader-p'>
                    Loading Resources, Wait!
                </p>
                <div className="main-loader-container">
                    <h1 className='loader-count'>{count}</h1>
                    <div class="loader_ring">
                    </div>
                </div>
            </div>
        )
    );
}

export default Loader;