import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ProjectHeader from '../../../ProjectsComponent/ProjectHeader/ProjectHeader';
import Testimonials from '../../../ProjectsComponent/Testimonials/Testimonials';
import TextRotator from '../../TextRotator/TextRotator';
import Footer from '../../Footer/Footer';
import Loader from '../../Loader/Loader';

const Projects = () => {

 

  return (
    <>
    <Loader pagekey="project_page"/>
      <Helmet>
        <title>Projects Page</title>
        <meta name='description' content='Explore our latest projects and testimonials. Witness the expertise of our team and the quality of our work. Let us bring your ideas to life!' />
        <link rel="canonical" href="/Projects" />
        <meta name='keywords' content='projects, portfolio, testimonials, clients,website' />
        <meta property='og:title' content='Projects Page' />
        <meta property='og:description' content='Explore our latest projects and testimonials. Witness the expertise of our team and the quality of our work. Let us bring your ideas to life!' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://devstacks.in/Projects' />
      </Helmet>

        <div className='main-project-container'>
          <ProjectHeader />
          <Testimonials />
          <TextRotator />
          <div className="sub-footer">
            <h1>Begin a conversation</h1>
            <p>devstacks@gmail.com
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.16699 4.6665H11.8337M11.8337 4.6665V15.3332M11.8337 4.6665L1.16699 15.3332" stroke="white" strokeWidth="2"/>
              </svg>
            </p>
          </div>
          <Footer />
        </div>

    </>
  );
}

export default Projects;
