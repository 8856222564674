import React from 'react'
import './Header.css'
import images from '../../Components/Assets/Assests'
const Header = () => {
  return (
    <div className='main-founder-container'>
      <div className="jatin-container">
        <div className="content-jatin">
          <h1 data-aos="fade-down">HI THERE, THIS IS ME,JATIN</h1>
          <img data-aos="fade-left" src={images.jatin} alt="" />
        </div>
        <div className="content-lalit">
          <img data-aos="fade-right" src={images.lalit} alt="" />
          <h1 data-aos="fade-up">HI THERE, THIS IS ME,LALIT</h1>
        </div>
      </div>
    </div>
  )
}

export default Header