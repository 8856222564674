import React from 'react'
import './Aboutus.css'
const Aboutus = () => {
  return (
    <div className='main-aboutus-container'>
      <div className="main-content-aboutus">
        <h1>About us</h1>
        <p>Our journey in web development and app design has been an exciting adventure for the two of us. As a dynamic duo, we've honed our skills to create websites, applications, and business solutions that are both innovative and user-friendly. Collaboration is at the heart of everything we do, and we're constantly pushing each other to learn and grow. From brainstorming sessions to coding marathons, we're always striving to exceed expectations. And when we're not busy crafting digital experiences, you'll find us exploring nature, seeking inspiration for our next big project</p>
      </div>
    </div>
  )
}

export default Aboutus