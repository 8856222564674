import React from 'react'
import './ContactHeader.css'
const ContactHeader = () => {
  return (
    <div className='main-contactheader-component'>
      <div className="main-contactheader-content">
        <h1 data-aos="fade-down">Fuel Your Brand's Goals with <span>Beyond</span></h1>
        <p data-aos="fade-up">You will get a response within 24 hours. We will explain in details how we can help you fuel and grow your brand within the stated budget.</p>
      </div>
    </div>
  )
}

export default ContactHeader