import project1Main from './images/rnvaastu1mainthumbnail.png'
import vaastu1 from './images/vaastuclient1part1.png'
import vaastu2 from './images/vaastuclient1part2.png'
import vaastu3 from './images/vaastuclient1part3.png'
import vaastu4 from './images/vaastuclient1part4.png'
import project2Main from './images/jaintours/mainimage1jaintours.png'
import jainfly1 from './images/jaintours/1jainfly.png'
import jainfly2 from './images/jaintours/2jainfly.png'
import jainfly3 from './images/jaintours/3jainfly.png'
import jainfly4 from './images/jaintours/4jainfly.png'
import project3Main from './images/billingimages/mainimg.png' 
import billing1 from './images/billingimages/1billing.png'
import billing2 from './images/billingimages/2billing.png'
import billing3 from './images/billingimages/3billing.png'
import billing4 from './images/billingimages/4billing.png'
import project4Main from './images/gstapp/Screenshot (397).png'
import gst1 from './images/gstapp/Screenshot (396).png'
import gst2 from './images/gstapp/Screenshot (398).png'
import gst3 from './images/gstapp/Screenshot (399).png'
import gst4 from './images/gstapp/Screenshot (400).png'
import project5Main from './images/Leadprovider/Screenshot (393).png'
import lead1 from './images/Leadprovider/Screenshot (393).png'
import lead2 from './images/Leadprovider/Screenshot (394).png'
import lead3 from './images/Leadprovider/Screenshot (395).png'
import lead4 from './images/Leadprovider/Screenshot (393).png'
export default{
    project1Main,
    project2Main,
    vaastu1,
    vaastu2,
    vaastu3,
    vaastu4,
    project3Main,
    billing1,
    billing2,
    billing3,
    billing4,
    jainfly1,
    jainfly2,
    jainfly3,
    jainfly4,
    project4Main,
    gst1,
    gst2,
    gst3,
    gst4,
    project5Main,
    lead1,
    lead2,
    lead3,
    lead4
}