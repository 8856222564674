import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./ProjectHeader.css";
import { ProjectContext } from "../ProjectContext/ProjectContext";

const ProjectHeader = () => {
  const projects = useContext(ProjectContext);

  const renderProjectItem = (project) => (
    <Link key={project.id} to={`/projects/${project.id}`} className="project-item">
      <div className="project-details">
        <img className="project-image" src={project.single_image} alt="Project" />
        <div className="text-content">
          <h2>{project.name}</h2>
          <p><span>Client:</span> {project.client_name}</p>
          <p><span>Work:</span> {project.work}</p>
        </div>
      </div>
    </Link>
  );

  if (!projects || projects.length === 0) {
    return <p>No projects found.</p>;
  }

  return (
    <div className="project-header-container">
      <div className="project-list">
        {projects.map((project) => renderProjectItem(project))}
      </div>
    </div>
  );
};

export default ProjectHeader;
