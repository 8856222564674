import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <div className='main-header-container'>
      <h1 className='header-title' data-aos="fade-down" data-aos-once="false" >Devstacks</h1>
      <p className='header-description1' data-aos="fade">
        Transform your ideas into stunning websites. Explore our tools, tutorials, and community support to kickstart your web development journey.
      </p>
      <div className='header-cta' data-aos="fade-up">
        {/* Use Link to navigate to Contact page */}
        <Link to='/contact' className='cta-button'>Get Started</Link>

        {/* Use Link to navigate to About page */}
        <Link to='/about' className='cta-button secondary'>Learn More</Link>
      </div>
    </div>
  );
};

export default Header;
