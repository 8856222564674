// App.js
import React, { useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/navbar/Navbar';
import Home from './Components/Pages/Home/Home';
import Projects from './Components/Pages/Projects/Projects';
import Contact from './Components/Pages/Contact/Contact';
import ProductDetails from './ProjectsComponent/ProductDetails/ProductDetails';
import BlogsContainer from './Components/Blogs/Blogsmain/BlogsContainer';
import Chatbot from './Chatbot/Chatbot';
import BlogDetails from './Components/Blogs/BLogsdetaile/BlogsDetails';
import { ProjectProvider } from './ProjectsComponent/ProjectContext/ProjectContext';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import About from './Components/Pages/About/About';
const App = () => {

  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 50,
      easing: "ease-out",
      offset: 200,
      mirror: true,
      anchorPlacement: "top-bottom",
      once: false,
    });

   
  }, []);

  useEffect(() => {
    Aos.refresh();
  }, []);

  return (
    <ProjectProvider>
      <Router>
        <div className="app-container">
          <Navbar />
          <Chatbot />
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path='/about' element={<About />} />

                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/:id" element={<ProductDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/all-blogs" element={<BlogsContainer />} />
                <Route path="/blog/:blogId" element={<BlogDetails />} />
              </Routes>
            </div>
        </div>
      </Router>
    </ProjectProvider>
  );
};

export default App;
