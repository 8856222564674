import React, { createContext, useState } from 'react';
import images from '../../Components/ProjectAssests/assests';

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [projects] = useState([
    {
      id: 1,
      name: "RN Vaastu Consultant",
      client_name: "Rajesh Nakar",
      work: "Full-Stack Development",
      single_image: images.project1Main,
      four_images:[images.vaastu1,images.vaastu2,images.vaastu3,images.vaastu4],
      youtube_link:"https://www.youtube.com/watch?v=QTf2sB6FAwc",
      description:"Experience the seamless blend of design and functionality on rnvaastuconsultant.com, crafted by DevStacks. Our team meticulously curated the website to reflect the essence of RN Vaastu Consultant's services. With a user-friendly interface, visitors can effortlessly explore Vaastu solutions for homes and businesses. DevStacks implemented modern web technologies to ensure smooth navigation and accessibility across devices. The website showcases the expertise of RN Vaastu Consultant through captiva"

    },
    {
      id: 2,
      name: "Jain Tours And Travels",
      client_name: "Danish Jain",
      work: "Web Application",
      single_image: images.project2Main,
      four_images:[images.jainfly1,images.jainfly2,images.jainfly3,images.jainfly4],
      youtube_link:"https://www.youtube.com/watch?v=jEumXl-tSh0",
      title:"Effortless Flight Data Transformation and Organization",
      description:"Our platform takes complex flight information and converts it into clear, easy-to-read tables, simplifying analysis and enhancing understanding. Ideal for aviation professionals, travel agencies, and frequent flyers, our tool helps you track flights, compare schedules, and manage travel plans with precision and ease. Experience the convenience of seamless data conversion and efficient sorting features designed to save you time and improve data accuracy."
    },{
      id: 3,
      name: "GST Software",
      client_name: "Saraswati Medical",
      work: "Web Application",
      single_image: images.project4Main,
      four_images:[images.gst1,images.gst2,images.gst3,images.gst4],
      youtube_link:"https://youtu.be/mrZeKrgS2vQ?si=g-Ty2LzXqfX2_p3x",
      title:"Effortless GST Management for Medical Institutions",
      description:"Experience seamless GST management tailored specifically for medical institutions with our intuitive software solution. Input transaction details effortlessly and watch as our system automatically generates GST-compliant invoices or Excel files with precision. Download your documents in a snap, saving valuable time and ensuring accurate tax documentation. Simplify your tax processes and focus on what truly matters – providing exceptional care to your patients."
    },{
      id: 4,
      name: "Bill Generation App",
      client_name: "Rajesh Nakar",
      work: "Web Application",
      single_image: images.project3Main,
      four_images:[images.billing1,images.billing2,images.billing3,images.billing4],
      youtube_link:"https://youtu.be/svVCL9iY-pg?si=Lg6v8DwE07dSqlp-",
      title:"Streamlined Billing: Effortless Solutions for Your Transactions",
      description:"The system is designed to be user-friendly, ensuring that anyone can easily input necessary information and obtain a professional bill. Additionally, the generated bills are available for download, providing convenience and efficiency for managing transactions and keeping records. This software is ideal for businesses of all sizes looking to streamline their billing process."
    },{
      id: 5,
      name: "Lead Generation Software ",
      client_name: "Devstacks",
      work: "Web Application",
      single_image: images.project5Main,
      four_images:[images.lead1,images.lead2,images.lead3,images.lead4],
      youtube_link:"https://youtu.be/NkkDlimrYC4?si=8n0jfLfJMQXNMRsz",
      title:"Streamlined Lead Management System",
      description:" Revolutionize your lead management with our intuitive system. Effortlessly input customer details and trigger automated emails to leads, all while preventing duplicates for a seamless follow-up process. Simplify your lead tracking and focus on converting prospects into loyal customers."
    },
  ]);

  return (
    <ProjectContext.Provider value={projects}>
      {children}
    </ProjectContext.Provider>
  );
};
