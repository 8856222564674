import maharaj from './images/maharaj.png'
import jatin from './images/jatinblackwhite.jpg'
import lalit from './images/lalitblackandwhite.jpg'
import rajesh from './images/image.png'
import Blog1  from  './images/blog1.jpeg'
import Blog2  from  './images/Blog2.jpg'
import Blog3  from  './images/BLog3.jpeg'
import Blog4  from  './images/BLog4.jpeg'
import Blog5  from  './images/blog5.png'
import Blog6  from  './images/Blog6.jpg'
import Blog7  from  './images/Blog7.jpg'
import chatbotsvg from './svg/robot-line-icon.svg'
import review2 from './images/reviewsperson2logo.jpg'
import logo from './images/logomain.png'
import close from './svg/closeicon.svg'
export default{
    maharaj,
    jatin,
    lalit,
    rajesh,
    Blog1,
    Blog2,
    Blog3,
    Blog4,
    Blog5,
    Blog6,
    Blog7,
    chatbotsvg,
    review2,
    logo,
    close
}