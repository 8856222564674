import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import images from "../../Assets/Assests";
import "./BlogsDetails.css";

const BlogDetails = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);

  const blogs = [
    {
      id: "1",
      image: images.Blog1,
      date: "Oct 7, 2023",
      title: "The Importance of Responsive Web Design",
      description: `In today's digital age, where users access websites through a multitude of devices ranging from smartphones and tablets to desktop computers, responsive web design has become more critical than ever. Responsive web design refers to the approach of creating websites that adapt and provide optimal user experiences across different screen sizes and devices.<br><br>
        <strong>Key Components of Responsive Design</strong><br>
        - Fluid Grid Layout: Using relative units (like percentages) for layout elements ensures that they adapt based on screen size.<br>
        - Media Queries: CSS media queries enable different styles to be applied based on screen characteristics such as width, height, and orientation.<br>
        - Flexible Images and Media: Images and media content should be scalable to prevent distortion or overflow on smaller screens.<br><br>
        <strong>Best Practices for Responsive Web Design</strong><br>
        - Prioritize mobile-first design: Start with designing for mobile devices and then scale up for larger screens.<br>
        - Use responsive frameworks like Bootstrap or CSS Grid to expedite development.<br>
        - Test your website across various devices and screen sizes to ensure consistent performance.<br><br>
        <strong>Conclusion</strong><br>
        Responsive web design is not just a trend; it's a necessity in today's digital landscape. Embracing responsive design principles ensures that your website remains user-friendly, accessible, and competitive in the ever-evolving world of the internet.`,
    },
    {
      id: "2",
      image: images.Blog2,
      date: "Dec 23, 2023",
      title: "Optimizing Your Website for Search Engines",
      description: `In today's competitive online landscape, ensuring that your website is optimized for search engines (SEO) is crucial for attracting organic traffic and improving visibility in search results. Search engine optimization involves implementing various strategies to enhance your website's relevance and authority in the eyes of search engines like Google, Bing, and others.<br><br>
        <strong>Key Strategies for Optimizing Your Website:</strong><br>
        - Keyword Research<br>
        - On-Page Optimization<br>
        - Quality Content Creation<br>
        - Technical SEO<br>
        - Link Building<br>
        - Local SEO<br>
        - Monitor and Adjust<br><br>
        By implementing these SEO strategies, you can enhance your website's search engine rankings, attract more organic traffic, and ultimately achieve your online marketing goals.`,
    },
    {
      id: "3",
      image: images.Blog3,
      date: "April 28, 2024",
      title: "The Impact of Social Media Marketing",
      description: `
        Social media marketing allows businesses to connect with their target audience and build brand awareness. Platforms like Facebook, Instagram, and Twitter offer powerful tools for reaching potential customers and engaging with existing ones. By creating compelling content and leveraging social media advertising, businesses can drive traffic to their websites and increase sales.<br><br>
        <strong>Key Benefits and Impacts of Social Media Marketing:</strong><br><br>
        - <strong>Brand Awareness:</strong> Social media platforms provide an effective way to increase brand visibility and recognition among users. A strong social media presence can enhance brand credibility and trust.<br><br>
        - <strong>Audience Engagement:</strong> Businesses can interact directly with their audience through comments, messages, and posts, fostering relationships and customer loyalty. Engaged followers are more likely to become brand advocates.<br><br>
        - <strong>Traffic Generation:</strong> Social media can drive significant traffic to websites, leading to increased opportunities for conversions and sales. Well-targeted social media campaigns can attract qualified leads.<br><br>
        - <strong>Customer Insights:</strong> Through social media analytics, businesses gain valuable insights into customer behaviors, preferences, and demographics. This data can inform marketing strategies and product development.<br><br>
        - <strong>Cost-Effective Advertising:</strong> Compared to traditional marketing channels, social media advertising can be more cost-effective and targeted. Businesses can optimize ad spending by reaching specific demographics and measuring campaign performance.<br><br>
        - <strong>Community Building:</strong> Social media allows businesses to build communities around their brand, fostering discussions and creating a sense of belonging among followers.<br><br>
        - <strong>Influencer Partnerships:</strong> Collaborating with influencers can amplify brand reach and credibility, leveraging their influence to promote products or services.<br><br>
        Social media marketing has become an essential component of digital marketing strategies, enabling businesses to reach wider audiences, drive engagement, and achieve business objectives.
      `,
    }, {
      id: "4",
      image: images.Blog4,
      date: "June 15, 2024",
      title: "Best Practices for User Experience (UX) Design",
      description: `
        User experience (UX) design is crucial for creating intuitive and engaging digital products that delight users. Here are some best practices to enhance UX design:<br><br>
        <strong>1. Understand User Needs:</strong> Conduct user research to understand the goals, behaviors, and pain points of your target audience.<br><br>
        <strong>2. Clear Navigation:</strong> Design an intuitive navigation system that helps users easily find what they're looking for.<br><br>
        <strong>3. Responsive Design:</strong> Ensure your design adapts seamlessly to different devices and screen sizes for a consistent user experience.<br><br>
        <strong>4. Consistency:</strong> Maintain visual and functional consistency throughout the interface to reduce cognitive load and enhance usability.<br><br>
        <strong>5. Accessibility:</strong> Design with accessibility in mind to ensure all users, including those with disabilities, can use your product effectively.<br><br>
        <strong>6. Use of White Space:</strong> Incorporate adequate white space to improve readability and focus on key elements.<br><br>
        <strong>7. Feedback and Error Handling:</strong> Provide clear feedback and error messages to guide users and reduce frustration.<br><br>
        <strong>8. Performance Optimization:</strong> Optimize performance to ensure fast loading times and smooth interactions.<br><br>
        <strong>9. User Testing:</strong> Continuously test your design with real users to gather feedback and make iterative improvements.<br><br>
        <strong>10. Continuous Improvement:</strong> UX design is an iterative process. Continuously collect data, analyze user behavior, and refine your design based on insights.<br><br>
        By implementing these best practices, UX designers can create user-centric products that drive engagement, satisfaction, and business success.
      `,
    }, {
      id: "5",
      image: images.Blog5,
      date: "August 21, 2024",
      title: "Harnessing the Power of Content Marketing",
      description: `
        Content marketing is a strategic approach focused on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience. Here's how businesses can leverage the power of content marketing:<br><br>
        <strong>1. Define Your Audience:</strong> Understand your target audience's preferences, pain points, and interests to tailor your content accordingly.<br><br>
        <strong>2. Develop a Content Strategy:</strong> Plan your content creation efforts based on your business goals, audience insights, and industry trends.<br><br>
        <strong>3. Create High-Quality Content:</strong> Produce engaging and informative content, including blog posts, articles, videos, infographics, and more.<br><br>
        <strong>4. SEO Optimization:</strong> Optimize your content for search engines to improve visibility and attract organic traffic.<br><br>
        <strong>5. Consistent Publishing Schedule:</strong> Maintain a regular publishing schedule to keep your audience engaged and establish credibility.<br><br>
        <strong>6. Promote Across Channels:</strong> Share your content on social media, email newsletters, and other relevant platforms to reach a wider audience.<br><br>
        <strong>7. Measure and Analyze:</strong> Use analytics tools to track content performance, understand audience behavior, and refine your strategy.<br><br>
        <strong>8. Engage with Your Audience:</strong> Encourage interaction through comments, shares, and discussions to foster a sense of community.<br><br>
        <strong>9. Adapt and Evolve:</strong> Stay agile and responsive to changing trends and feedback, continuously refining your content strategy.<br><br>
        <strong>10. Focus on Value:</strong> Prioritize delivering value to your audience through educational, entertaining, or inspiring content that addresses their needs.<br><br>
        Content marketing is a powerful tool for building brand awareness, driving traffic, and nurturing relationships with your audience. By implementing these strategies, businesses can maximize the impact of their content efforts.
      `,
    },
    {
      id: "6",
      image: images.Blog6,
      date: "September 15, 2024",
      title: "The Role of Video in Digital Marketing",
      description: `
        Video has emerged as a powerful medium in digital marketing, offering unique advantages for brands looking to engage audiences and drive conversions. Here's why video is essential in modern marketing:<br><br>
        <strong>1. Captivating Visual Content:</strong> Videos capture attention and convey information more effectively than text or images alone, making them ideal for storytelling and brand promotion.<br><br>
        <strong>2. Increased Engagement:</strong> Videos encourage interaction and sharing, leading to higher engagement rates on social media and websites.<br><br>
        <strong>3. Improved SEO:</strong> Websites with video content often rank higher in search engine results, boosting organic visibility and traffic.<br><br>
        <strong>4. Versatile Content Formats:</strong> Videos can be used for product demonstrations, tutorials, testimonials, webinars, live streams, and more, catering to diverse marketing needs.<br><br>
        <strong>5. Enhanced Brand Personality:</strong> Video allows brands to showcase their personality, values, and culture, forging deeper connections with audiences.<br><br>
        <strong>6. Mobile-Friendly Experience:</strong> With the rise of mobile usage, videos are easily consumable on smartphones and tablets, reaching users on the go.<br><br>
        <strong>7. Higher Conversion Rates:</strong> Video content has been shown to increase conversion rates, driving more leads and sales for businesses.<br><br>
        <strong>8. Social Media Dominance:</strong> Platforms like YouTube, TikTok, and Instagram prioritize video content, making them essential for social media marketing strategies.<br><br>
        <strong>9. Measurable Results:</strong> Marketers can track video performance metrics such as views, watch time, and engagement, enabling data-driven optimizations.<br><br>
        <strong>10. Long-Term Impact:</strong> Compelling videos have a lasting impact on audiences, fostering brand recall and loyalty over time.<br><br>
        Incorporating video into digital marketing strategies can significantly enhance brand visibility, audience engagement, and overall marketing effectiveness in today's competitive landscape.
      `,
    },
    {
      id: "7",
      image: images.Blog7,
      date: "November 10, 2024",
      title: "Understanding the Basics of Web Development",
      description: `
        Web development encompasses the creation and maintenance of websites and web applications. It involves various technologies and processes that work together to deliver content and functionality to users. Here are key aspects of web development:<br><br>
        <strong>1. Frontend Development:</strong><br>
        Frontend development focuses on the client-side of web applications, including the user interface and user experience. It involves HTML, CSS, and JavaScript to create interactive and visually appealing web pages that users interact with directly.<br><br>
        <strong>2. Backend Development:</strong><br>
        Backend development handles the server-side logic and database interactions of web applications. It includes languages like Node.js, Python, Ruby, and frameworks such as Express.js, Django, and Ruby on Rails. Backend developers build the server infrastructure and APIs that power the frontend.<br><br>
        <strong>3. Full-Stack Development:</strong><br>
        Full-stack developers work on both frontend and backend technologies, allowing them to develop complete web applications independently. They have knowledge of both client-side and server-side programming, along with databases and deployment processes.<br><br>
        <strong>4. Web Development Tools:</strong><br>
        Developers use various tools and technologies to streamline the development process. This includes code editors (e.g., Visual Studio Code, Sublime Text), version control systems (e.g., Git), and package managers (e.g., npm, Yarn). Frameworks like React, Angular, and Vue.js are popular for frontend development.<br><br>
        <strong>5. Responsive Web Design:</strong><br>
        With the increasing use of mobile devices, responsive web design ensures that websites display correctly on all screen sizes. CSS frameworks like Bootstrap and CSS Grid facilitate responsive design by providing flexible layout components.<br><br>
        <strong>6. Web Performance Optimization:</strong><br>
        Optimizing web performance is crucial for user experience and SEO. Techniques like code minification, image optimization, lazy loading, and caching improve website speed and responsiveness.<br><br>
        <strong>7. Continuous Learning:</strong><br>
        Web development is a dynamic field that requires continuous learning and adaptation to new technologies and best practices. Developers often engage in online courses, tutorials, and community forums to stay updated.<br><br>
        Understanding the basics of web development provides a foundation for building modern, functional, and scalable web applications. Whether you're a beginner or experienced developer, mastering web development concepts opens up exciting opportunities in the digital world.
      `,
    },

  ];

  useEffect(() => {
    const selectedBlog = blogs.find((blog) => blog.id === blogId);
    setBlog(selectedBlog);
  }, [blogId]);

  if (!blog) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="blog-details-container" >
      <img src={blog.image} alt={blog.title} />
      <div className="details-text">
        <p>{blog.date}</p>
        <h2>{blog.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: blog.description }} />
      </div>
    </div>
  );
};

export default BlogDetails;