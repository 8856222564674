import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../../../AboutComponents/Header/Header';
import Aboutus from '../../../AboutComponents/Aboutus/Aboutus';
import ExperienceAboutus from '../../../AboutComponents/ExperienceAboutus/ExperienceAboutus';
import Reviews from '../../Reviews/Reviews';
import TextRotator from '../../TextRotator/TextRotator';
import Footer from '../../Footer/Footer';
import Loader from '../../Loader/Loader';

const About = () => {
  return (
    <>
    <Loader pagekey="page_about" />
    <div className='main-about-container'>

      <Helmet>
        <title>About Page</title>
        <meta name='description' content='Discover the magic of creating your dream website with ease and delight. Start your journey to an enchanting web experience today.' />
        <link rel="canonical" href="/About" />
        <meta name='keywords' content='about us, company, team, mission,website' />
        <meta property='og:title' content='About Page' />
        <meta property='og:description' content='Discover the magic of creating your dream website with ease and delight. Start your journey to an enchanting web experience today.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://devstacks.in/About' />
      </Helmet>
      <Header />
      <Aboutus />
      <ExperienceAboutus />
      <Reviews />
      <TextRotator />
      <div className="sub-footer">
        <h1>Begin a conversation</h1>
        <p>devstacks@gmail.com
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.16699 4.6665H11.8337M11.8337 4.6665V15.3332M11.8337 4.6665L1.16699 15.3332" stroke="white" stroke-width="2"/>
          </svg>
        </p>
      </div>
      <Footer />
    </div>
    </>
  );
}

export default About;
